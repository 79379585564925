<template>
    <ClientOnly>
        <div
            v-if="widgetId"
            v-bind="$attrs"
            ref="trustpilotWidget"
            class="trustpilot-widget"
            data-locale="en-US"
            :data-template-id="widgetId"
            data-businessunit-id="60da0dfdb3519f0001b7357f"
            :data-style-height="heightPx + 'px'"
            data-style-width="100%"
            data-theme="light"
            data-stars="1,2,3,4,5"
            data-review-languages="en"
            data-text-color="#101820"
        >
            <a
                href="https://www.trustpilot.com/review/www.roastar.com"
                target="_blank"
                rel="noopener"
            >
                Trustpilot
            </a>
        </div>
    </ClientOnly>
</template>

<script setup lang="ts">
useHead({
    script: [
        {
            src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
            async: true,
        },
    ],
});

const props = defineProps<{
    type: 'grid' | 'mini-carousel';
    heightPx: number;
}>();

const trustpilotWidget = ref<HTMLDivElement | null>(null);

const availableWidgetIds = {
    grid: '539adbd6dec7e10e686debee',
    'mini-carousel': '539ad0ffdec7e10e686debd7',
};

const widgetId = computed(() => {
    return availableWidgetIds[props.type];
});

onMounted(async () => {
    await nextTick();
    if (widgetId.value && trustpilotWidget.value && window.Trustpilot) {
        window.Trustpilot.loadFromElement(trustpilotWidget.value);
    }
});
</script>
